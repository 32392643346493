import { useCallback, useState } from 'react';

/**
 * Similar to React.useState, but is persistent in Local Storage
 */
export function useLocalStorageState<T>(key: string, defaultValue: T | (() => T)) {
  const [value, setValue] = useState<T>(() => {
    const value = localStorage.getItem(key);
    if (value !== null) {
      try {
        return JSON.parse(value);
      } catch (e) {
        console.warn(`useLocalStorageState: failed to parse item "${key}", value: ${value}`);
      }
    }
    return typeof defaultValue === 'function' ? (defaultValue as () => T)() : defaultValue;
  });

  const handleValueChange = useCallback((newValue: T) => {
    setValue(newValue);
    localStorage.setItem(key, JSON.stringify(newValue));
  }, [key, setValue]);

  return [value, handleValueChange] as const;
}
