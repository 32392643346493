import React from 'react';
import { useCollection } from "@cloudscape-design/collection-hooks";
import { Header, Pagination, Table } from "@cloudscape-design/components";
import type { QuizLevelMetricsWithQuizId } from "../../../interfaces/metrics";
import type { QuizIndexing } from "../../../interfaces/snapshot";
import LinkToEditorSite from "../../shared/linkToEditorSite";
import { InfoPopover } from '../../shared/infoPopover';

export const QuizLevelMetricsTable: React.FC<{
  quizLevelMetrics: QuizLevelMetricsWithQuizId[],
  quizIndexing: QuizIndexing,
  loading: boolean,
}> = ({ quizLevelMetrics, quizIndexing, loading }) => {

  // Merge metrics and quiz content flatly, because Cloudscape Table cannot sort nested field
  const quizLevelMetricsAndContent = quizLevelMetrics.map(metrics => ({
    ...metrics,
    ...quizIndexing[metrics.quiz_id],
  }));
  
  const collection = useCollection(
    quizLevelMetricsAndContent,
    {
      pagination: { pageSize: 5 },
      // filtering: {
      //   empty: "No quiz data.",
      //   noMatch: "No quiz found with given ID.",
      //   filteringFunction: (row, filteringText) => `${row[0]}`.indexOf(filteringText) === 0,
      // },
      sorting: {
        defaultState: {
          sortingColumn: { sortingField: 'quiz_play_customer' },
          isDescending: true
        }
      },
    }
  );
  const { items, actions, filteredItemsCount, collectionProps, filterProps, paginationProps } = collection;

  return (
    <Table
      {...collectionProps}
      items={items}
      header={<Header variant="h3">Quiz Level Metrics</Header>}
      loading={loading}
      columnDefinitions={[
        {
          id: "link",
          header: null,
          cell: row => row.locale && <LinkToEditorSite quizId={row.quiz_id} locale={row.locale} />
        },
        {
          id: "quizId",
          header: "Quiz ID",
          cell: row => row.quiz_id,
          sortingField: "quiz_id",
        },
        {
          id: "quizName",
          header: "Quiz Name",
          cell: row => <div style={{ overflow: "hidden" }}>{row.quiz_name}</div>,
          sortingField: "quiz_name",
          maxWidth: 200,
        },
        {
          id: "quizPlayCustomer",
          header: <>Customers<InfoPopover top content='The count of unique CIDs.' /></>,
          cell: row => row.quiz_play_customer,
          sortingField: "quiz_play_customer"
        },
        {
          id: "quizPlayCount",
          header: <>Quiz Plays (Dialogs)<InfoPopover top content='Quiz plays is the same number as Dialogs.' /></>,
          cell: row => row.quiz_play_count,
          sortingField: "quiz_play_count"
        }
      ]}
      // filter={<TextFilter {...filterProps} filteringPlaceholder="Find Quiz by ID"/>}
      pagination={<Pagination {...paginationProps} />}
    />
  );
}
