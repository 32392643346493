import React, {useRef, useState} from 'react';
import AppLayout from '@cloudscape-design/components/app-layout';
import Flashbar, {FlashbarProps} from '@cloudscape-design/components/flashbar';
import {Breadcrumbs, ServiceNavigation} from '../navigation';
import {appLayoutLabels} from '../../common/labels';
import {toolsTopics} from "../shared/tools";
import {Visualizations} from "./visualizations";
import {Button, SpaceBetween, Header, DateRangePickerProps, Container, ContentLayout} from "@cloudscape-design/components";
import {DataSelector} from "./dataSelector";
import {QuizSelector} from "./quizSelector";
import {QuizPlayFilter, type QuizPlayFilterType} from "./quizPlayFilter";
import {MetricsDownload} from "./metricsDownload";
import {DownloadStatus} from "./metricsDownload/downloadStatus";
import {DownloadStatusBar} from "./metricsDownload/downloadStatusBar";
import {QuizSummaryTable} from "./tables/quizSummaryTable";
import {QuizLevelMetricsTable} from "./tables/quizLevelMetricsTable";
import {FrictionTable} from "./tables/frictionTable";
import {getInitialDateRange} from '../../common/dataSelector';
import {QuizJsons} from '../../interfaces/snapshot';
import {getQuizSummaryMetrics, getHighFrictionQuestions, getQuestionLevelMetrics, getQuizLevelMetrics, getQuizJsons} from '../../common/computationLambda';
import type {DashboardUser} from "../../interfaces";
import {HighFrictionQuestion, QuestionLevelMetrics, QuizLevelMetrics, QuizLevelMetricsWithQuizId} from '../../interfaces/metrics';
import {useQuizIndexing} from '../../hooks/useQuizIndexing';
import {useRefState} from '../../hooks/useRefState';
import {VIEWPORT_FEATURE_START_DATE_STR} from '../../common/date';

export function AnalyticsLayout({user}: {user: DashboardUser}) {

  const [toolsTopic, setToolsTopic] = useState('analyticsInfo');
  const [toolsOpen, setToolsOpen] = useState(false);

  const onTopicSelect = function (topic: string) {
    setToolsTopic(topic);
    setToolsOpen(true);
  };

  // global notification for the analytics page
  const [notificationItems, setNotificationItems] = useState<FlashbarProps.MessageDefinition[]>([]);
  const fixedNotifications: FlashbarProps.MessageDefinition[] = [{
    content: (<span>
      Thank you for using Quizzical Goblin Dashboard. 
      If you encounter any issue or want new feature, please <a href="https://i.amazon.com/issues/create?template=cc69f567-5d0f-41e7-af35-eaab877c0ffb">submit a SIM</a> here. 
    </span>),
    loading: false, type: "info"
  }, {
    content: (<span>
      We have recently fixed an issue that all metrics were lower than actual for some dates between Oct and Dec 2023, due to that part of data failed to process at backend. 
      Please refer to the current values as accurate values. <a href="https://t.corp.amazon.com/D111664872/">Learn more</a> 
    </span>),
    loading: false, type: "info"
  }];
  
  const [downloadStatus, setDownloadStatus, downloadStatusRef] = useRefState<DownloadStatus>([]);
  

  // Input
  const [dateRange, setDateRange] = useState<DateRangePickerProps.AbsoluteValue>(getInitialDateRange());
  const [selectedQuizIds, setSelectedQuizIds] = useState<number[]>([]);
  const [quizPlayFilter, setQuizPlayFilter] = useState<QuizPlayFilterType>({viewportTypes: []});
  // Metrics
  const [quizSummaryMetrics, setQuizSummaryMetrics] = useState<QuizLevelMetrics>();
  const [highFrictionQuestions, setHighFrictionQuestions] = useState<HighFrictionQuestion[]>([]);
  const [quizLevelMetrics, setQuizLevelMetrics] = useState<QuizLevelMetricsWithQuizId[]>([]);
  const [questionLevelMetrics, setQuestionLevelMetrics] = useState<QuestionLevelMetrics[] & {dateRange?: DateRangePickerProps.AbsoluteValue}>([]);
  const [quizJsons, setQuizJsons] = useState<QuizJsons>({});
  const quizJsonsRef = useRef<QuizJsons>({});
  // Loading
  const [isLoadingQuizSummaryMetrics, setIsLoadingQuizSummaryMetrics] = useState(false);
  const [isLoadingHighFrictionQuestions, setIsLoadingHighFrictionQuestions] = useState(false);
  const [isLoadingQuizLevelMetrics, setIsLoadingQuizLevelMetrics] = useState(false);
  const [isLoadingQuestionLevelMetrics, setIsLoadingQuestionLevelMetrics] = useState(false);
  // Quiz Indexing
  const { quizIndexing, isLoadingQuizIndexing } = useQuizIndexing();

  // @ts-ignore
  window.states = {quizSummaryMetrics, highFrictionQuestions, quizLevelMetrics, questionLevelMetrics, quizJsons};

  const isViewportSelectorDisabled = dateRange.startDate < VIEWPORT_FEATURE_START_DATE_STR;

  const onClickApply = () => {
    const dateStart = dateRange.startDate;
    const dateEnd = dateRange.endDate;
    console.log('onClickApply')
    try {
      const viewportTypes = !isViewportSelectorDisabled && quizPlayFilter.viewportTypes.length ? quizPlayFilter.viewportTypes : undefined;

      setIsLoadingQuizSummaryMetrics(true);
      getQuizSummaryMetrics(dateStart, dateEnd, selectedQuizIds.length ? selectedQuizIds : undefined, viewportTypes).then(result => {
        setIsLoadingQuizSummaryMetrics(false);
        result?.metrics?.[0] && setQuizSummaryMetrics(result.metrics[0]);
      })

      setIsLoadingHighFrictionQuestions(true);
      getHighFrictionQuestions(dateStart, dateEnd, undefined, viewportTypes).then((result: {metrics: HighFrictionQuestion[]}) => {
        setIsLoadingHighFrictionQuestions(false);
        setHighFrictionQuestions(
          (result.metrics ?? []).filter(i => selectedQuizIds.length === 0 || selectedQuizIds.includes(i.quiz_id))
        );

        if (result.metrics?.length) {
          const highFrictionQuizIds = result.metrics.map(({quiz_id}) => quiz_id);
          console.log('highFrictionQuizIds', highFrictionQuizIds)
          highFrictionQuizIds.length && getQuizJsons(highFrictionQuizIds).then(quizJsonResult => {
            quizJsonsRef.current = {
              ...quizJsonsRef.current,
              ...quizJsonResult.quiz_jsons,
            }
            setQuizJsons(quizJsonsRef.current);
          })
        }
      })

      setIsLoadingQuizLevelMetrics(true);
      getQuizLevelMetrics(dateStart, dateEnd, undefined, viewportTypes).then(result => {
        setIsLoadingQuizLevelMetrics(false);
        setQuizLevelMetrics(
          (result.metrics ?? []).filter(i => selectedQuizIds.length === 0 || selectedQuizIds.includes(i.quiz_id))
        );
      })

      if (selectedQuizIds.length === 1) {
        setIsLoadingQuestionLevelMetrics(true);
        getQuestionLevelMetrics(dateStart, dateEnd, selectedQuizIds[0], viewportTypes).then(result => {
          setIsLoadingQuestionLevelMetrics(false);
          setQuestionLevelMetrics(result.metrics ?? [])
        })
      } else {
        setQuestionLevelMetrics([]);
      }

      if (selectedQuizIds.length > 0 && selectedQuizIds.length <= 10) {
        getQuizJsons(selectedQuizIds).then(quizJsonResult => {
          quizJsonsRef.current = {
            ...quizJsonsRef.current,
            ...quizJsonResult.quiz_jsons,
          }
          setQuizJsons(quizJsonsRef.current);
        })
      }
    } catch(e) {console.error(e);}
  }

  const isApplyButtonLoading = isLoadingHighFrictionQuestions || isLoadingQuestionLevelMetrics || isLoadingQuizLevelMetrics || isLoadingQuizSummaryMetrics;

  return (
    <AppLayout
      content={
        <ContentLayout disableOverlap header={
          <Header variant="h1" actions={
            <Button iconName="search" href="https://quip-amazon.com/coG5ASuWpvCz/Quizzical-Goblin-Metrics-Dictionary" target="_blank">
              Metrics Dictionary
            </Button>
          }>
            Quizzical Goblin Dashboard
          </Header>
        }>
          <SpaceBetween size={"l"}>
            <DataSelector dateRange={dateRange} setDateRange={setDateRange}
                          setNotificationsItems={setNotificationItems} infoTopicSelector={onTopicSelect}/>
            <QuizSelector selectedQuizIds={selectedQuizIds} setSelectedQuizIds={setSelectedQuizIds} quizIndexing={quizIndexing} loadingQuizIndexing={isLoadingQuizIndexing}
                          setNotificationsItems={setNotificationItems} infoTopicSelector={onTopicSelect}/>
            <QuizPlayFilter value={quizPlayFilter} onChange={(v: {[field: string]: any}) => setQuizPlayFilter({...quizPlayFilter, ...v})} isViewportSelectorDisabled={isViewportSelectorDisabled} />

            <SpaceBetween direction="horizontal" size="s">
              <Button variant={"primary"} disabled={false} loading={isApplyButtonLoading} onClick={onClickApply}>Load Metrics</Button>
              <MetricsDownload dateRange={dateRange} selectedQuizIds={selectedQuizIds} quizPlayFilter={quizPlayFilter} downloadStatusRef={downloadStatusRef} setDownloadStatus={setDownloadStatus} />
            </SpaceBetween>

            <QuizSummaryTable quizSummaryMetrics={quizSummaryMetrics} loading={isLoadingQuizSummaryMetrics} infoTopicSelector={onTopicSelect}/>
            <QuizLevelMetricsTable quizLevelMetrics={quizLevelMetrics} quizIndexing={quizIndexing} loading={isLoadingQuizLevelMetrics}/>
            <FrictionTable highFrictionQuestions={highFrictionQuestions} quizIndexing={quizIndexing} quizJsons={quizJsons} loading={isLoadingHighFrictionQuestions} infoTopicSelector={onTopicSelect} />
            {
              selectedQuizIds.length === 1 ? (
                <Visualizations 
                  quizJson={quizJsons[selectedQuizIds[0]]} questionLevelMetrics={questionLevelMetrics}
                  loading={isLoadingQuestionLevelMetrics} infoTopicSelector={onTopicSelect}
                />
              ) : (
                <Container 
                  header={<Header variant="h3">Question Metrics and Transition Graph are available only when one quiz is selected.</Header>}
                />
              )
            }
          </SpaceBetween>
          <DownloadStatusBar downloadStatus={downloadStatus} setDownloadStatus={setDownloadStatus} />
        </ContentLayout>
      }
      notifications={<Flashbar items={[...fixedNotifications, ...notificationItems]}/>}
      // breadcrumbs={<Breadcrumbs items={[{text: 'Analytics', href: ''}]}/>}
      // navigation={<ServiceNavigation/>}
      // navigationOpen={false}
      navigationHide
      tools={toolsTopics[toolsTopic]}
      toolsOpen={toolsOpen}
      onToolsChange={event => setToolsOpen(event.detail.open)}
      ariaLabels={appLayoutLabels}
    />
  );
}
