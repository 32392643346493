import HelpPanel from '@cloudscape-design/components/help-panel';
import React from "react";

export const toolsTopics: Record<string, React.ReactNode> = {
  // List of Help (right) panel content, changes depending on which 'info' link the user clicks on.
  dataSelectorInfo: (
    <HelpPanel
      header={<h2>Goblin Analytics</h2>}
    >
      <p>
        By default, when the page is loaded, a default range of past 2 days will be selected. Aggregated Views for this date range
        will be downloaded, and processed, post which you can begin applying filters to filter out sessions matching your criteria.
        All visualizations will be updated automatically based on this filtered data.
      </p>
    </HelpPanel>
  ),
  filtersInfo: (
    <HelpPanel
      header={<h2>Quiz Selection</h2>}
    >
      <p>
        This is the first step in controlling which set of data you analyze. Choosing a date range will download all sessions for that
        date range, and these sessions will be filtered based on the selections you make in this section for different criteria such as
        locale and Quiz ID. Note that, if no value is selected for a filter, it is considered inactive (not <i>filter all
        sessions which have a value other than the ones listed in the dropdowns.</i>
      </p>
    </HelpPanel>
  ),
  frictionInfo: (
    <HelpPanel
      header={<h2>Friction</h2>}
    >
      <h5>What is a friction?</h5>
      <p>
        In Quizzical quizzes, the editors offer a list of correct and incorrect answers to match the player’s utterances. 
        We detect a friction for a specific question when <b>no items in the list match the player’s answer</b>. 
        When it happens, Alexa will respond with “sorry, i can’t understand”, which is customizable on the Quizzial Editorial Site. 
        If the friction response count is configured for the quiz, Quizzical will offer to skip the question if the total friction count exceeds the limit. 
      </p>
      <h5>How to fix it?</h5>
      <p>
        To reduce the friction, editors can provide a more comprehensive list of answers, change “Match Type” to “Contains”, or create a “Catch all” node (only available to adventure quizzes).
      </p>
      <p>See <a href='https://wiki.labcollab.net/confluence/display/Doppler/Quizzical+User+Guide+-+Friction'>Quizzical User Guide - Friction</a> for more information.</p>
      <h5>How is friction case counted?</h5>
      <p>
        When counting friction by cases (not unique customers), one customer might cause multiple friction cases at one question when they repeatedly attempt to answer it; 
        and they might replay the whole quiz multiple times, which causes more friction cases.
        <br/>
        Friction rate is defined as (# friction cases) / (# friction cases + # visits).
        <br/>
        For example, if someone met two frictions at a question, and replayed the quiz, and met two frictions there again, then the question metrics would be:
        <br/> - Visits: 2
        <br/> - Visits by customer: 1
        <br/> - Frictions: 4 (67%)
        <br/> - Frictions by customer: 1 (50%)
        <br/> - Summary question friction rate: 67%
      </p>
    </HelpPanel>
  ),
  finishingNodeIssue: (
    <HelpPanel
      header={<h2>Issue with the finishing nodes</h2>}
    >
      <p>
        Data associated with the finishing nodes of decision tree quiz and adventure quiz were not recorded before 2023-07-21. <br/>
        Thus, metrics with start date earlier than 2023-07-21 have following inaccuracies:
        <ul>
          <li> In the Question Level Metrics table, the <i>visit</i> and <i>friction</i> of the finishing nodes are lower than actual. </li>
          <li> In the Question Level Metrics table, the <i>drop rate</i> of the second-last nodes are lower than actual. </li>
          <li> In the Summary table, the <i>total question asked</i> and <i>average quiz duration</i> are slightly lower than actual. </li>
        </ul>
      </p>
    </HelpPanel>
  )
};


