import * as React from "react";
import Popover from "@cloudscape-design/components/popover";
import { displaySpeech } from "../../common/utils";

export const SpeechPreview: React.FC<{
  speech: string | undefined
}> = ({ speech }) => {
  const displayedSpeech = displaySpeech(speech);
  return (
    <Popover
      dismissAriaLabel="Close"
      position="top"
      size="large"
      content={displayedSpeech}
    >
      <span style={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}>
        {displayedSpeech}
      </span>
    </Popover>
  )
};

export default SpeechPreview;
