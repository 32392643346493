import G6, { Graph } from '@antv/g6';
import { Button } from '@cloudscape-design/components';
import React, { useEffect, useRef } from 'react';
import { GREY_400, RED_600 } from '../../../common/colors';

const BLUE = '#5B8FF9';
const NAVY = '#00287E';
const GREY = '#414d5c';
const SCARLET = '#831f1f';

export interface TransitionGraphData {
  nodes: {
    id: string,
    speech: string,
    views: string,
    duration: string,
    drops: string,
    warning: boolean,
  }[],
  edges: {
    id: string,
    source: string,
    target: string,
    display: string,
  }[],
}

export const TransitionGraph = React.memo<{ data: TransitionGraphData }>(({ data }) => {
  console.log('Render TransitionGraph')

  const ref = useRef<HTMLDivElement | null>(null);
  const toolbarRef = useRef(null);

  const graph = useRef<Graph | null>(null);

  useEffect(() => {

    graph.current?.destroy();
    const toolbar = new G6.ToolBar({
      container: toolbarRef.current
    });
    graph.current = new G6.Graph({
      container: ref.current!,
      width: 1500,
      height: 800,
      fitViewPadding: 20,
      layout: {
        type: 'dagre',
        nodesep: 50,
        ranksep: 150,
        rankdir: 'LR',
        controlPoints: true,
      },
      defaultNode: {
        type: 'custom-node',
      },
      defaultEdge: {
        type: 'custom-edge',
        style: {
          radius: 20,
          offset: 45,
          endArrow: { path: G6.Arrow.vee() },
          lineWidth: 2,
          stroke: '#D'
        },
      },
      plugins: [toolbar],
      modes: {
        default: [
          'drag-canvas',
          'drag-node'
        ],
      },
    });

    graph.current.data(data);
    graph.current.render();
    graph.current.on('afterlayout', () => graph.current?.fitView());

  }, [data])

  const onClickDownload = () => graph.current?.downloadFullImage(
    'transitions', 'image/jpeg', { backgroundColor: '#ffffff' }
  );

  return (
    <>
      <div style={{ position: 'relative' }}>
        <div ref={toolbarRef} style={{ position: 'absolute', right: '50px' }} />
      </div>
      <div ref={ref} style={{ height: 800 }} />
      <div style={{ textAlign: 'right' }}>
        <Button variant={'normal'} onClick={onClickDownload}>Download image</Button>
      </div>
    </>
  );
})


function registerGraphStyle() {
  G6.registerEdge(
    'custom-edge',
    {
      afterDraw(cfg, group) {
        if (!group || !cfg) {
          return;
        }
        const shape = group.get('children')[0];
        shape.setAttr('stroke', GREY_400);
        shape.setAttr('lineWidth', 4);
        const midPoint = shape.getPoint(0.4);
        group.addShape('text', {
          attrs: {
            text: cfg!.display,
            x: midPoint.x,
            y: midPoint.y,
            fill: GREY,
            fontSize: 28,
            textAlign: 'center',
            textBaseline: 'middle',
            fontWeight: 'bold',
          },
        });
      },
      update: undefined,
    },
    'polyline',
  );

  const defaultTextStyle = {
    fontSize: 18,
    textAlign: 'center',
    textBaseline: 'middle',
    fontWeight: 'bold',
  } as const;

  G6.registerNode(
    'custom-node',
    {
      drawShape(cfg, group) {
        const rect = group!.addShape('rect', {
          attrs: {
            x: -150,
            y: -50,
            width: 300,
            height: 100,
            radius: 10,
            stroke: cfg!.warning ? RED_600 : BLUE,
            lineWidth: 3,
          },
        });
        group!.addShape('text', {
          attrs: {
            text: cfg!.speech,
            x: 0,
            y: -32,
            fill: NAVY,
            ...defaultTextStyle,
            fontSize: 20,
          },
        });
        group!.addShape('text', {
          attrs: {
            text: cfg!.views,
            x: 0,
            y: -10,
            fill: GREY,
            ...defaultTextStyle,
          },
        });
        group!.addShape('text', {
          attrs: {
            text: cfg!.duration,
            x: 0,
            y: 12,
            fill: GREY,
            ...defaultTextStyle,
          },
        });
        group!.addShape('text', {
          attrs: {
            text: cfg!.drops,
            x: 0,
            y: 34,
            fill: SCARLET,
            ...defaultTextStyle,
          },
        })
        return rect;
      },
    },
    'single-node',
  );
}
registerGraphStyle();
