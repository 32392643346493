
import * as React from "react";
import Icon from "@cloudscape-design/components/icon";

export const LinkToEditorSite: React.FC<{
  locale: string,
  quizId: number | string,
}> = ({ locale, quizId }) => {
  return (
    <a
      title="view quiz in editorial site"
      href={`https://prod.pdx.quizzical.apac.alexa.a2z.com/editor/${locale}/published/${quizId}`}
      target="_blank"
    >
      <Icon name="external" variant="link" />
    </a>
  );
};

export default LinkToEditorSite;
