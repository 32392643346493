import React, { ReactNode } from 'react';
import { Box, ColumnLayout, Container, Header, Link, SpaceBetween, Spinner, TextContent } from '@cloudscape-design/components';
import { displaySeconds } from '../../../common/utils';
import type { QuizLevelMetrics } from '../../../interfaces/metrics';
import { InfoPopover } from '../../shared/infoPopover';


const labelAndValue = ({ label, value, tooltip }: { label: string, value?: string | null, tooltip?: ReactNode }) => (
  <div key={label}>
    <Box variant='awsui-key-label'>
      {label}
      {tooltip && <InfoPopover content={tooltip}/>}
    </Box>
    <div>{value ?? '-'}</div>
  </div>
);

export const QuizSummaryTable: React.FC<{
  quizSummaryMetrics?: QuizLevelMetrics,
  loading: boolean,
  infoTopicSelector: any,
}> = ({ quizSummaryMetrics, loading, infoTopicSelector }) => {

  const entries = [
    {
      label: 'Total customers',
      value: quizSummaryMetrics?.quiz_play_customer?.toLocaleString(),
      tooltip: 'The count of unique CIDs.'
    },
    {
      label: 'Total quiz plays (Dialogs)',
      value: quizSummaryMetrics?.quiz_play_count?.toLocaleString(),
      tooltip: 'Same customer replaying a quiz is counted as two quiz plays, even though in one Alexa session.'
    },
    {
      label: 'Total questions asked (Utterances)',
      value: quizSummaryMetrics?.utterance_count?.toLocaleString(),
      tooltip: 'This includes the quiz-launch utterance, but does not include the quiz instruction at quiz start, and asking for replay or feedback at quiz end.'
    },
    {
      label: 'Question friction rate',
      value: quizSummaryMetrics?.friction_count_rate?.toLocaleString(undefined, { style: 'percent' }),
      tooltip: <>
        <TextContent><p style={{ backgroundColor: '#f4f4f4' }}><code>
          = # total friction utterances / # total answer utterances
        </code></p></TextContent>
        <Link variant='info' onFollow={() => infoTopicSelector('frictionInfo')}>What is a friction?</Link>
      </>
    },
    {
      label: 'Quiz completion rate',
      value: quizSummaryMetrics?.quiz_complete_count_rate?.toLocaleString(undefined, { style: 'percent' }),
    },
    {
      label: 'Average quiz duration',
      value: displaySeconds(quizSummaryMetrics?.duration_avg),
      tooltip: <>
        The timing starts when asking customer the first question and ends when receiving customer's answer to the last question. <br/>
        The duration of asking customer <i>"Do you want to hear the instruction?"</i> is excluded. <br/>
        The duration of any response after the answering the last question (such as response to the last answer, asking for feedback, suggesting another quiz) is also excluded.
      </>
    },
  ]

  return (
    <Container header={
      <Header variant="h3">Summary of Selected Quizzes {loading ? <Spinner /> : undefined}</Header>
    }>
      <ColumnLayout columns={2} variant="text-grid">
        <SpaceBetween size="s">
          {entries.slice(0, 3).map(labelAndValue)}
        </SpaceBetween>
        <SpaceBetween size="s">
          {entries.slice(3, 6).map(labelAndValue)}
        </SpaceBetween>
      </ColumnLayout>
    </Container>
  );
}
