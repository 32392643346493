/*
 * Returns all aggregated user groups from POSIX, LDAP, ANT.
 *
 * Taken from https://tiny.amazon.com/9gqsiwkj
 */
export const getAllUserGroupsFromIdToken = (idToken: any) => {
  console.log('getAllUserGroupsFromIdToken', idToken)
  let groups = []
  if (idToken.payload['custom:ldap'])
    groups = idToken.payload['custom:ldap'].split(',').map((item: string) => item.trim())
  if (idToken.payload['custom:posix'])
    groups = groups.concat(idToken.payload['custom:posix'].split(',').map((item: string) => item.trim()))
  if (idToken.payload['custom:ant'])
    groups = groups.concat(idToken.payload['custom:ant'].split(',').map((item: string) => item.trim()))
  return groups
}

/**
 * Denied users - federate profile is configured to allow access for alexa-employees. We can control there, instead of here for now.
 *
 * TODO add support for groups, after we can get ldap, posix claims from federate.
 */
export const DASHBOARD_ACCESS_DENIED_USERS: string[] = [] // just for testing.

// if user authorized.
export const isUserAuthorized = (uid: string) => {
  return !DASHBOARD_ACCESS_DENIED_USERS.some(user => user === uid)
}
