import { useCollection } from "@cloudscape-design/collection-hooks";
import { CollectionPreferences, CollectionPreferencesProps, Icon, Link, Pagination, Table, TextContent, TextFilter } from "@cloudscape-design/components";
import { GREY_550, RED_600 } from "../../../common/colors";
import { ProcessedQuestionMetrics } from "./processVisualizationData";
import { SPECIAL_NODES, displayNumberAndPercentage, displaySeconds } from "../../../common/utils";
import { InfoPopover } from "../../shared/infoPopover";
import SpeechPreview from "../../shared/speechPreview";
import { useLocalStorageState } from "../../../hooks/useLocalStorageState";


const TABLE_PREFERENCE_LOCAL_STORAGE_KEY = 'GoblinQuestionMetricsTablePreference';

const WARNING_STYLE: React.CSSProperties = {
  backgroundColor: RED_600,
  color: '#fff',
};

const WarningSpan: React.FC<React.PropsWithChildren<{ isWarning: boolean }>> = ({ isWarning, children }) => (
  <span style={isWarning ? WARNING_STYLE : undefined}>
    {children}
  </span>
);

const speechEndInfoPopover = (
  <InfoPopover top content={<>
    <TextContent><p style={{ backgroundColor: '#f4f4f4' }}><code>
      = (# question speech completes playing) / (# visits)
    </code></p></TextContent>
    <p>Based on data from APL-supported (multimodal) devices, starting from 2023-12-09.</p>
  </>} />
);

const naText = <TextContent><small>N/A</small></TextContent>;

const contentDisplayOptions: CollectionPreferencesProps.ContentDisplayOption[] = [
  { id: 'questionID', label: 'Question ID' },
  { id: 'speech', label: 'Speech' },
  { id: 'viewCount', label: 'Visits' },
  { id: 'viewCustomer', label: 'Visits by customer' },
  { id: 'frictionCount', label: 'Frictions' },
  { id: 'frictionCustomer', label: 'Frictions by customer' },
  { id: 'durationAvg', label: 'Average duration' },
  { id: 'dropCountRate', label: 'Drop rate' },
  { id: 'speechEndCountRate', label: 'APL speech-play-complete rate' },
];

export function QuestionMetricsTable(props: {
  data: ProcessedQuestionMetrics[],
  loading: boolean,
  hasFinishingNodeIssue: boolean,
  infoTopicSelector: (topic: string) => void,
}) {
  console.log('Render QuestionMetricsTable')
  const [preferences, setPreferences] = useLocalStorageState<CollectionPreferencesProps.Preferences>(
    TABLE_PREFERENCE_LOCAL_STORAGE_KEY,
    // hide questionID column by default
    { contentDisplay: contentDisplayOptions.map(({ id }) => ({ id, visible: id !== 'questionID' })) }
  );
  const { items, actions, filteredItemsCount, collectionProps, filterProps, paginationProps, propertyFilterProps } = useCollection(
    props.data,
    {
      filtering: {},
      sorting: {},
      pagination: { pageSize: 30 },
    }
  );

  const frictionInfoTopicSelector = (
    <Link variant='info' onFollow={() => props.infoTopicSelector('frictionInfo')}>What is a friction?</Link>
  );
  const frictionInfoPopover = (
    <InfoPopover top content={<>
      <TextContent><p style={{ backgroundColor: '#f4f4f4' }}><code>
        = (# friction cases) / (# friction cases + # visits)
      </code></p></TextContent>
      {frictionInfoTopicSelector}
    </>}/>
  );
  const frictionCustomerInfoPopover = (
    <InfoPopover top content={<>
      <TextContent><p style={{ backgroundColor: '#f4f4f4' }}><code>
        = (# friction unique customers) /<br />(# friction unique customers + # unique customers)
      </code></p></TextContent>
      {frictionInfoTopicSelector}
    </>}/>
  );

  return (
    <Table
      loading={props.loading}
      {...collectionProps}
      columnDisplay={preferences.contentDisplay}
      columnDefinitions={[{
        id: 'questionID',
        header: 'Question ID',
        cell: item => (SPECIAL_NODES.includes(item.id) ? '-' : item.id.substring(0, 8)),
        sortingField: "id"
      }, {
        id: 'speech',
        header: 'Speech',
        cell: item => (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {props.hasFinishingNodeIssue && item.node.is_end ? <Icon name='status-warning' size='small' variant='subtle' /> : undefined}
            <div style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
              <SpeechPreview speech={item.node?.speech ?? ""} />
            </div>
          </div>
        ),
        sortingField: "speech"
      }, {
        id: 'viewCount',
        header: 'Visits',
        cell: item => item.viewCount.toLocaleString(window.navigator.language),
        sortingField: "viewCount",
      }, {
        id: 'viewCustomer',
        header: 'Visits by customer',
        cell: item => item.viewCustomer.toLocaleString(window.navigator.language),
        sortingField: "viewCustomer",
      }, {
        id: 'frictionCount',
        header: <>Frictions{frictionInfoPopover}</>,
        cell: item => (
          <span style={item.frictionCountWarning ? WARNING_STYLE : undefined}>
            {item.frictionCount === null ? naText : displayNumberAndPercentage(item.frictionCount, item.frictionCountRate)}
          </span>
        ),
        sortingField: "frictionCountRate"
      }, {
        id: 'frictionCustomer',
        header: <>Frictions by customer{frictionCustomerInfoPopover}</>,
        cell: item => (
          <span style={item.frictionCustomersWarning ? WARNING_STYLE : undefined}>
            {item.frictionCustomers === null ? naText : displayNumberAndPercentage(item.frictionCustomers, item.frictionCustomersRate)}
          </span>
        ),
        sortingField: "frictionCustomersRate"
      }, {
        id: 'durationAvg',
        header: 'Average duration',
        cell: item => displaySeconds(item.durationAvg) ?? naText,
        sortingField: 'durationAvg'
      }, {
        id: 'dropCountRate',
        header: 'Drop rate',
        cell: item => (
          <span style={item.dropCountWarning ? WARNING_STYLE : undefined}>
            {displayNumberAndPercentage(item.dropCount, item.dropCountRate)}
          </span>
        ),
        sortingField: 'sortingField',
      }, {
        id: 'speechEndCountRate',
        header: <>APL speech-play-complete rate{speechEndInfoPopover}</>,
        cell: item => item.speechEndCountRate?.toLocaleString(window.navigator.language, { style: 'percent' }) ?? naText,
        sortingField: 'speechEndCountRate',
      }]}
      items={items}
      stickyHeader
      resizableColumns
      wrapLines
      filter={
        <TextFilter
          {...filterProps}
          filteringAriaLabel="Filter"
          filteringPlaceholder="Filter"
        />
      }
      pagination={<Pagination {...paginationProps} />}
      footer={props.hasFinishingNodeIssue ? (
        <div
          style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', color: GREY_550, cursor: 'pointer' }}
          onClick={() => props.infoTopicSelector('finishingNodeIssue')}
        >
          <Icon name='status-warning' size='small' />&nbsp;<span style={{ textDecoration: 'underline' }}>Issue with the finishing nodes</span>
        </div>
      ) : undefined}
      preferences={
        <CollectionPreferences
          preferences={preferences}
          onConfirm={({ detail }) => setPreferences(detail)}
          contentDisplayPreference={{
            options: contentDisplayOptions,
            title: 'Column visibility and order',
          }}
          cancelLabel='Cancel'
          confirmLabel='Confirm'
        />
      }
    />
  );
}
