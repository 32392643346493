import { MultiselectProps } from "@cloudscape-design/components";
import type { AggregatedView, State, Transition, Metadata } from "../interfaces";
import type { QuizJson } from "../interfaces/snapshot";

export const MILLIS_IN_DAY = 24 * 60 * 60 * 1000;
export const MILLIS_IN_FIFTEEN_MINUTES = 15 * 60 * 1000;
export const MILLIS_IN_HOUR = 60 * 60 * 1000;
export const MILLIS_IN_THREE_HOURS = 3 * 60 * 60 * 1000;
export const MILLIS_IN_MINUTES = 60 * 1000;


/**
 * Convert milliseconds to a human-readable format.
 */
export function convertMillsToReadable(time: number): string {
  // try hours
  if (Math.floor(time / MILLIS_IN_DAY) > 0) {
    return `${(time / MILLIS_IN_DAY).toFixed(1)} d`;
  } else if (Math.floor(time / MILLIS_IN_HOUR)) {
    return `${(time / MILLIS_IN_HOUR).toFixed(1)} h`;
  } else if (Math.floor(time / MILLIS_IN_MINUTES)) {
    return `${(time / MILLIS_IN_MINUTES).toFixed(1)} m`;
  } else {
    return `${(time / 1000).toFixed(1)} s`;
  }
}


export function displayMsAsS(millis: number) {
  return `${(millis / 1000).toFixed(1)} s`;
}


export function displaySeconds(sec: number | null | undefined): string | null {
  return typeof sec === 'number' ?  sec.toFixed(1) + ' s' : null;
}


/**
 * Convert numbers to readable format.
 */
export function convertNumbersToReadable(value: number): string {
  return Math.abs(value) >= 1e9
    ? (value / 1e9).toFixed(2).replace(/\.0$/, "") +
    "G"
    : Math.abs(value) >= 1e6
      ? (value / 1e6).toFixed(2).replace(/\.0$/, "") +
      "M"
      : Math.abs(value) >= 1e3
        ? (value / 1e3).toFixed(2).replace(/\.0$/, "") +
        "K"
        : value.toFixed(2);
}

/**
 * Convert dates to readable format in charts.
 */
export function convertDateToReadable(date: Date): string {
  return date.toLocaleDateString(window.navigator.language, {
    month: "short",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    hour12: !1
  })
    .split(",")
    .join("\n")
}

/**
 * Get selected values from a multi-select filter.
 */
export function getMultiSelectValues(filterState: MultiselectProps.Options | undefined): string[] {
  return (filterState ?? [])
    .map(option => 'value' in option ? option['value'] : null)
    .filter(x => x !== null) as string[]
}

/**
 * Counts frequencies of values within a list.
 */
export function frequencyCounter(list: string[]): { [value: string]: number } {
  const result: { [key: string]: number } = {};
  list.forEach(val => {
    if (!result.hasOwnProperty(val)) result[val] = 0;
    result[val] += 1;
  });
  return result;
}

/**
 * Converts a number to a particular number of fractional digits.
 */
export function numberWithFraction(value: number, fraction?: number): number {
  if (typeof fraction === 'undefined') return value;
  return Number(value.toFixed(fraction));
}

/**
 * Sums a list.
 */
export function sumValue(list: number[]): number {
  if (list.length === 0) return 0;
  let sum = 0;
  list.forEach(val => {
    sum = sum + val;
  });
  return sum;
}

/**
 * Averages a list, and returns the average with the desired precision.
 */
export function averageValue(list: number[], precision?: number): number {
  if (list.length === 0) return 0;
  let sum = 0;
  list.forEach(val => {
    sum = sum + val;
  });
  return numberWithFraction(sum / list.length, precision);
}

/**
 * Get error message.
 */
export function getErrorMessage(error: any) {
  let message = 'unknown';
  if (error instanceof Error) message = `${error.message}`;
  if (typeof error === 'string') message = error;
  return message;
}

export function getUniqueCustomerCount(avs: AggregatedView[]): number {
  const customer = new Set<string>();
  avs.forEach(av => customer.add(av.customerIdentifier))
  return customer.size
}

export function getStateNameForDisplay(state: State, showTags: boolean) {
  if (showTags && typeof state.tag !== 'undefined')
    return `${state.name}/${state.tag}`;
  // if (!['state_quiz_start', 'state_quiz_end', 'SESSION_END'].includes(state.name))
    // return state.name.substring(0, 8);
  return state.name;
}

export function getTransitionNameForDisplay(transition: Transition, showTransitionTags: boolean): string {
  let result = typeof transition.reason === 'undefined' ? undefined : transition.reason!;
  if (showTransitionTags && typeof transition.transitionTag !== 'undefined')
    result = (result ? result + "/" : '') + transition.transitionTag!
  return result ? result : "*";
}

export function getStateKey(state: State, showTags: boolean) {
  if (showTags && typeof state.tag !== 'undefined') return `state=${state.name},tag=${state.tag!}`
  return `state=${state.name}`;
}

export function getTransitionKey(transition: Transition, showTags: boolean, showTransitionTags: boolean) {
  let key = `origin=${getStateKey(transition.origin, showTags)},destination=${getStateKey(transition.destination, showTags)}`;
  if (transition.reason !== 'undefined') key = `${key},reason=${transition.reason}`
  if (showTransitionTags && typeof transition.transitionTag !== 'undefined') key = `${key},tag=${transition.transitionTag}`
  return key;
}


/**
 * Remove xml tags in a text
 */
export function displaySpeech(speech: string | undefined) {
  if (!speech) return '';
  const parser = new DOMParser();
  try {
    const xmlDoc = parser.parseFromString(`<html>${speech}</html>`, "application/xml");
    xmlDoc.querySelector('parsererror')?.remove();
    // Return original speech if speech consists of only tags or if error
    return xmlDoc.documentElement.textContent ?? speech;
  } catch (e) {
    return speech;
  }
}

/** E.g. (1234, 0.456) => "1,234 (46%)" */
export function displayNumberAndPercentage(number: number|null, percentage: number|null) {
  if (number === null || percentage === null) {
    return 'N/A';
  }
  const displayNumber = number.toLocaleString(window.navigator.language);
  const displayPercentage = percentage.toLocaleString(window.navigator.language, { style: 'percent' });
  return `${displayNumber} (${displayPercentage})`;
}

export function displayPercentageOf(number: number, cardinality: number) {
  return displayNumberAndPercentage(number, number / cardinality);
}

/**
 * Get single metadata value by name
 */
export function getMetadata(av: AggregatedView, metadataName: string) {
  return av.metadata[metadataName]?.["1"]?.values?.[0]?.values;
}

/**
 * For each [key, value] pair, merge values with same key into a map.
 * e.g. [['a', 0], ['a', 1]] -> {'a': [0, 1]}
 */
export function aggregate<K, V>(items: V[], getKey: (item: V) => K) {
  const result = new Map<K, V[]>();
  for (const item of items) {
    const key = getKey(item);
    const itemsOfKey = result.get(key);
    if (itemsOfKey) {
      itemsOfKey.push(item);
    } else {
      result.set(key, [item]);
    }
  }
  return result;
}

/**
 * Zip two arrays, depending on the length of first array.
 * e.g. ([1,2,3],[a,b,c]) -> [[1,a],[2,b],[3,c]]
 */
export function zip<A, B>(a: A[], b: B[]): [A, B][] {
  return a.map((k, i) => [k, b[i]]);
}

/**
 * Count the number of distince values
 * e.g. ([1,1,2,3,2]) -> 3
 */
export function countDistinct<T>(items: T[]) {
  return new Set(items).size;
}


export function countDistinctCustomer<T extends { customerIdentifier: string }>(
  items: T[]
) {
  return countDistinct(items.map(i => i.customerIdentifier))
}

/**
 * Apply map on a Map, just like Array.map().
 */
export function mapMap<K, V, W>(m: Map<K, V>, mapper: (value: V) => W): Map<K, W> {
  return new Map(
    [...m].map(([key, value]) => [
      key, mapper(value)
    ]
    ));
}

/**
 * Get a value if key exists or create a value if not exist. Then return the value.
 */
export function getOrCreate<K, V>(map: Map<K, V>, key: K, getNewValue: (key: K) => V): V {
  let value = map.get(key);
  if (!value) {
    value = getNewValue(key);
    map.set(key, value);
  }
  return value;
}


/**
 * [1,2,undefined].filter(notUndefined) === [1,2]
 */
export function notUndefined<T>(element: T | undefined): element is T {
  return typeof element !== 'undefined';
}


export const SPECIAL_NODES = ['state_quiz_start', 'state_quiz_end'];


export function isRandomQuiz(quizJson: QuizJson) {
  return quizJson.editor_props?.type === 'random';
}
