import { useCallback, useRef, useState } from 'react';

/**
 * Create a State together with a Ref, so that we can access the up-to-date state value in callback.
 */
export function useRefState<T>(initialState: T | (() => T)): [T, (newValue: T) => void, { readonly current: T }] {
  const [state, setState] = useState(initialState);
  const ref = useRef(state);
  const setStateAndRef = useCallback((newValue: T) => {
    setState(newValue);
    ref.current = newValue;
  }, []);
  return [state, setStateAndRef, ref];
}
