import React from "react";
import { type DateRangePickerProps, Tabs } from "@cloudscape-design/components";
import { getProcessedQuestionMetrics, getTransitionGraphData } from "./processVisualizationData";
import type { QuestionLevelMetrics } from "../../../interfaces/metrics";
import type { QuizJson } from "../../../interfaces/snapshot";
import { QuestionMetricsTable } from "./questionMetricsTable";
import { TransitionGraph } from "./transitionGraph";
import { isRandomQuiz } from "../../../common/utils";

interface VisualizationsProps {
  loading: boolean;
  quizJson?: QuizJson;
  questionLevelMetrics: QuestionLevelMetrics[] & { dateRange?: DateRangePickerProps.AbsoluteValue };
  infoTopicSelector: (topic: string) => void;
}

export function Visualizations(props: VisualizationsProps) {

  const processedQuestionMetrics = getProcessedQuestionMetrics(props.quizJson ?? {}, props.questionLevelMetrics);
  console.log('processedQuestionMetrics', processedQuestionMetrics)
  const transitionGraphData = getTransitionGraphData(processedQuestionMetrics);
  console.log('transitionGraphData', transitionGraphData)

  const startDate = props.questionLevelMetrics.dateRange?.startDate;
  const hasFinishingNodeIssue = !!startDate && startDate < '2023-07-21';

  return (
    <Tabs tabs={[{
      id: 'questionLevelMetrics',
      label: 'Question level metrics',
      content: <QuestionMetricsTable
        data={processedQuestionMetrics}
        loading={props.loading}
        hasFinishingNodeIssue={hasFinishingNodeIssue}
        infoTopicSelector={props.infoTopicSelector}
      />
    }, {
      id: 'transitionGraph',
      label: 'Transition graph',
      content: props.quizJson && isRandomQuiz(props.quizJson) ?
        <div>Transition graph is not available for Random Quiz</div>
        :
        <TransitionGraph data={transitionGraphData} />
    }]} />
  );
}
