import {AnalyticsLayout} from "./components/analytics";
import React from "react";
import {Auth} from "aws-amplify";
import {getAllUserGroupsFromIdToken, isUserAuthorized} from "./utils/permissions";
import {DashboardUser} from "./interfaces";
import Flashbar from "@cloudscape-design/components/flashbar";
import {HashRouter, Redirect, Route} from "react-router-dom";

// Class App is the "output" generated on every build,
// it is what you will see on the webpage.
// based on https://tiny.amazon.com/xjr2fmbb
export default function App() {

  const [user, updateUser] = React.useState<DashboardUser>();

  const parseAndUpdateUser = (cognitoUserData: any) => {
    const idToken = cognitoUserData.getSignInUserSession().getIdToken()
    const userId = idToken.payload['identities'][0].userId
    const groups = getAllUserGroupsFromIdToken(idToken)

    console.log('user id', userId)
    console.log('user groups', groups)
    updateUser({userId: userId, groups: groups})
  }

  React.useEffect(() => {
    console.log('checking user info...')

    Auth.currentAuthenticatedUser()
      .then(cognitoUserData => {
        parseAndUpdateUser(cognitoUserData)
      })
      .catch(() => {
        console.log('No signed in user. sign in...')
        Auth.federatedSignIn({customProvider: 'AmazonFederate'})
      })
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  if (typeof user === 'undefined') {
    return (
      <div>
        <Flashbar items={[{
          type: 'info',
          content: 'Signing you in...',
          loading: true,
          dismissible: false
        }]}/>
      </div>
    )
  } else if (!isUserAuthorized(user.userId)) {
    return (
      <div>
        <Flashbar items={[{
          type: 'error',
          content: 'You don\'t have access to this dashboard. Please contact sachigoe@ to request access.',
          dismissible: false
        }]}/>
      </div>
    )
  } else {
    return (
      <HashRouter>
        <div>
          <Route exact path="/"><Redirect to="/analytics"/></Route>
          <Route path="/analytics"><AnalyticsLayout user={user}/></Route>
        </div>
      </HashRouter>
    );
  }
}
