import React from 'react';
import { Header, Multiselect, type SelectProps, TextContent } from '@cloudscape-design/components';
import { notUndefined } from '../../../common/utils';
import { VIEWPORT_FEATURE_START_DATE_STR } from '../../../common/date';


export type QuizPlayFilterType = {
  viewportTypes: string[],
};

const viewportTypesOptions: SelectProps.Options = [
  { label: 'Headless', value: 'NO_VIEWPORT' },
  {
    label: 'Multimodal', options: [
      { label: 'HUB_ROUND_SMALL', value: 'HUB_ROUND_SMALL', description: 'shape=ROUND width=height width<600 height<600 120<dpi<=160' },
      { label: 'HUB_LANDSCAPE_SMALL', value: 'HUB_LANDSCAPE_SMALL', description: '120<dpi<=160 width>height width<1280 height<600' },
      { label: 'HUB_LANDSCAPE_MEDIUM', value: 'HUB_LANDSCAPE_MEDIUM', description: '120<dpi<=160 width>height width<1280 height<960' },
      { label: 'HUB_LANDSCAPE_LARGE', value: 'HUB_LANDSCAPE_LARGE', description: '120<dpi<=160 width>height width>=1280 height>=960' },
      { label: 'MOBILE_LANDSCAPE_SMALL', value: 'MOBILE_LANDSCAPE_SMALL', description: '160<dpi<=240 width>height width>=960 height>=600' },
      { label: 'MOBILE_PORTRAIT_SMALL', value: 'MOBILE_PORTRAIT_SMALL', description: '160<dpi<=240 width<height width>=600 height>=0' },
      { label: 'MOBILE_LANDSCAPE_MEDIUM', value: 'MOBILE_LANDSCAPE_MEDIUM', description: '160<dpi<=240 width>height width>=600 height>=0' },
      { label: 'MOBILE_PORTRAIT_MEDIUM', value: 'MOBILE_PORTRAIT_MEDIUM', description: '160<dpi<=240 width<height width>=0 height>=600' },
    ]
  },
  {
    label: 'Fire TV', options: [
      { label: 'TV_LANDSCAPE_XLARGE', value: 'TV_LANDSCAPE_XLARGE', description: 'dpi>240 width>height width>=1920 height>=960' },
      { label: 'TV_PORTRAIT_MEDIUM', value: 'TV_PORTRAIT_MEDIUM', description: 'dpi>240 width<height width<600 height>=1920' },
      { label: 'TV_LANDSCAPE_MEDIUM', value: 'TV_LANDSCAPE_MEDIUM', description: 'dpi>240 width>height 960<=width<1280 600<=height<960' },
    ]
  },
  { label: 'Other Devices with Screen', value: 'UNKNOWN_VIEWPORT_PROFILE' },
];

/** [{label: 'L', value: 'V'}] -> ['V'] */
function selectedOptionsToValues(selectedOptions: ReadonlyArray<SelectProps.Option>): string[] {
  return selectedOptions.map(option => option.value).filter(notUndefined);
}

/** ['V'] -> [{label: 'L', value: 'V'}] */
function valuesToSelectedOptions(values: string[], options: SelectProps.Options): SelectProps.Options {
  const ungroupedOptions: SelectProps.Option[] = options.flatMap(
    option => 'value' in option ? [option] : (option as SelectProps.OptionGroup).options
  );
  return values.map(
    value => ungroupedOptions.find(option => option.value === value)
  ).filter(notUndefined);
}

/** [{label: 'G', options: [{label: 'L', value: 'V'}]}] -> [{label: 'L', value: 'V'}] */
function flattenOptions(options: SelectProps.Options): ReadonlyArray<SelectProps.Option> {
  return options.flatMap(opt => 'options' in opt ? opt.options : opt);
}


function getViewportPlaceholderText(isViewportSelectorDisabled: boolean, selectedViewportLabels: (string | undefined)[]) {
  if (isViewportSelectorDisabled) return `Disabled: Not applicable to data before ${VIEWPORT_FEATURE_START_DATE_STR}.`;
  if (selectedViewportLabels.length) return `${selectedViewportLabels.length} selected: ${selectedViewportLabels.join(', ')} `;
  return 'All devices selected';
}

export const QuizPlayFilter: React.FC<{
  value: QuizPlayFilterType,
  onChange: (valueChange: Partial<QuizPlayFilterType>) => void,
  isViewportSelectorDisabled: boolean,
}> = ({ value, onChange, isViewportSelectorDisabled }) => {
  const { viewportTypes } = value;
  const selectedViewportOptions = valuesToSelectedOptions(viewportTypes, viewportTypesOptions);
  const selectedViewportLabels = flattenOptions(selectedViewportOptions).map(opt => opt.label);
  return <>
    <Header variant='h2'>
      Quiz-Play Filter
    </Header>
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <TextContent><h5 style={{ width: 120 }}>Device Type</h5></TextContent>
      <div style={{ width: 500 }}>
        <Multiselect
          options={viewportTypesOptions}
          selectedOptions={selectedViewportOptions}
          onChange={e => onChange({ viewportTypes: selectedOptionsToValues(e.detail.selectedOptions) })}
          placeholder={getViewportPlaceholderText(isViewportSelectorDisabled, selectedViewportLabels)}
          disabled={isViewportSelectorDisabled}
          hideTokens
        />
      </div>
    </div>
  </>
};
