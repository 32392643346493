import {
  displayNumberAndPercentage,
  notUndefined,
  displaySeconds,
  displaySpeech,
} from "../../../common/utils";
import type { TransitionGraphData } from "./transitionGraph";
import type { QuizJson, QuizJsonNode } from "../../../interfaces/snapshot";
import type { QuestionLevelMetrics } from "../../../interfaces/metrics";

const WARNING_THRESHOLD = 0.3; // Warn if friction rate or drop rate is above this.


export interface ProcessedQuestionMetrics {
  id: string,
  speech: string,

  viewCount: number,
  viewCustomer: number,
  frictionCount: number | null,
  frictionCountRate: number | null,
  frictionCountWarning: boolean,
  frictionCustomers: number | null,
  frictionCustomersRate: number | null,
  frictionCustomersWarning: boolean,
  dropCount: number | null,
  dropCountRate: number | null,
  dropCountWarning: boolean,
  durationAvg: number | null,
  speechEndCountRate: number | null,
  node: QuizJsonNode,
}


/**
 * Join metrics with quiz nodes
 * Compute frictionRate and dropRate
 * Warn if have finishing node issue
 */
export function getProcessedQuestionMetrics(
  quizJson: QuizJson,
  questionLevelMetrics: QuestionLevelMetrics[],
): ProcessedQuestionMetrics[] {

  const nodes: QuizJsonNode[] = [
    ...quizJson.nodes ?? [],
    ...['state_quiz_end'].map((nodeId) => ({
      id: nodeId,
      speech: `(${nodeId})`,
      is_end: true,
    })),
  ];


  const questionMetricsTableData: ProcessedQuestionMetrics[] = nodes.map(node => {
    const metrics = questionLevelMetrics.find(m => m.question_id === node.id);

    if (metrics === undefined) {
      return {
        id: node.id,
        speech: node.speech,
        viewCount: 0,
        viewCustomer: 0,
        frictionCount: 0,
        frictionCustomers: 0,
        frictionCountRate: 0,
        frictionCountWarning: false,
        frictionCustomersRate: 0,
        frictionCustomersWarning: false,
        dropCount: 0,
        dropCountRate: 0,
        dropCountWarning: false,
        durationAvg: null,
        speechEndCountRate: null,
        node,
      };
    }

    const dropCount = metrics.view_count - metrics.response_count;
    const dropCountRate = dropCount / metrics.view_count || 0;
    const dropCountWarning = dropCountRate >= WARNING_THRESHOLD && node.id !== 'state_quiz_end';
    return {
      id: node.id,
      speech: node.speech,
      viewCount: metrics.view_count,
      viewCustomer: metrics.view_customer,
      frictionCount: metrics.friction_count,
      frictionCountRate: metrics.friction_count_rate,
      frictionCountWarning: metrics.friction_count_rate !== null && metrics.friction_count_rate >= WARNING_THRESHOLD && node.id !== 'state_quiz_end',
      frictionCustomers: metrics.friction_customer,
      frictionCustomersRate: metrics.friction_customer_rate,
      frictionCustomersWarning: metrics.friction_customer_rate !== null && metrics.friction_customer_rate >= WARNING_THRESHOLD && node.id !== 'state_quiz_end',
      dropCount,
      dropCountRate,
      dropCountWarning,
      durationAvg: metrics.duration_avg,
      speechEndCountRate: metrics.speech_end_count_rate,
      node,
    };
  });

  return questionMetricsTableData;
}



export function getTransitionGraphData(questionMetrics: ProcessedQuestionMetrics[]): TransitionGraphData {

  const questionMetricsWithoutEndState = questionMetrics.filter(metrics => metrics.id !== 'state_quiz_end');
  
  const graphNodes: TransitionGraphData['nodes'] = questionMetricsWithoutEndState.map(metrics => {
    const viewCountString = metrics.viewCount.toLocaleString(window.navigator.language);
    const viewCustomerString = metrics.viewCustomer.toLocaleString(window.navigator.language);
    return {
      id:
        metrics.id,
      speech:
        displaySpeech(metrics.node.speech).slice(0, 25),
      views:
        `${viewCountString} Views / ${viewCustomerString} Customers`,
      duration:
        `Average Duration: ${displaySeconds(metrics.durationAvg) ?? 'null'}`,
      drops:
        `Drop rate: ${displayNumberAndPercentage(metrics.dropCount, metrics.dropCountRate)}`,
      warning:
        metrics.frictionCountWarning || metrics.dropCountWarning,
    }
  });

  // Get edge list from quizJson
  const edges = questionMetricsWithoutEndState.flatMap(({ node, viewCount }) => (
    node.next_nodes?.map(({ next_node_id }) => {
      const nextNodeMetrics = questionMetricsWithoutEndState.find(n => n.id === next_node_id);
      if (next_node_id === undefined || nextNodeMetrics === undefined) {
        return undefined;
      } 
      return {
        source: node.id,
        target: next_node_id,
        sourceViewCount: viewCount ?? 0,
        targetViewCount: nextNodeMetrics.viewCount ?? 0,
      };
    }).filter(notUndefined) ?? []
  ));

  const graphEdges: TransitionGraphData['edges'] = edges.map(({ source, target, sourceViewCount, targetViewCount }) => ({
    id: `${source}/${target}`,
    source,
    target,
    display: displayNumberAndPercentage(targetViewCount, targetViewCount / sourceViewCount || 0),
  }));

  return { nodes: graphNodes, edges: graphEdges };
}


function getNextNodes(node: QuizJsonNode, json: QuizJson): QuizJsonNode[] {
  return node.next_nodes?.map(
    next_node => json.nodes?.find(n => n.id === next_node.next_node_id)
  ).filter(notUndefined) ?? [];
}
