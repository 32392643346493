import type { QuizLevelMetrics, QuizLevelMetricsWithQuizId, QuestionLevelMetrics, HighFrictionQuestion } from '../../interfaces/metrics';

export const MOCK_SUMMARY_METRICS: [QuizLevelMetrics] = [{
  quiz_play_count: 2,
  quiz_play_customer: 1,
  quiz_complete_count: 2,
  quiz_complete_count_rate: 1.,
  quiz_complete_customer: 1,
  quiz_complete_customer_rate: 1.,
  utterance_count: 10,
  utterance_count_exclude_launch: 8,
  replay_count: 1,
  replay_rate: 0.5,
  immediate_replay_count: 0,
  immediate_replay_rate: 0.,
  duration_avg: 443.285,
  friction_count: 4,
  friction_count_rate: 4 / 14.,
  friction_customer: 1,
  friction_customer_rate: 1.,
}];

export const MOCK_QUIZ_LEVEL_METRICS: QuizLevelMetricsWithQuizId[] = [{
  quiz_id: 676,
  quiz_play_count: 2,
  quiz_play_customer: 1,
  quiz_complete_count: 0,
  quiz_complete_count_rate: 0.,
  quiz_complete_customer: 0,
  quiz_complete_customer_rate: 0.,
  utterance_count: 6,
  utterance_count_exclude_launch: 4,
  replay_count: 0,
  replay_rate: 0,
  immediate_replay_count: 0,
  immediate_replay_rate: 0,
  duration_avg: 443.285,
  friction_count: 4,
  friction_count_rate: 0.,
  friction_customer: 1,
  friction_customer_rate: 0.,
}];

export const MOCK_QUESTION_LEVEL_METRICS: QuestionLevelMetrics[] = [
  ...(['1', '2'].map(question_id => ({
    question_id,
    view_count: 2,
    view_customer: 1,
    response_count: 2,
    response_count_rate: 1.,
    response_customer: 1,
    response_customer_rate: 1.,
    friction_count: 0,
    friction_count_rate: 0.,
    friction_customer: 0,
    friction_customer_rate: 0.,
    duration_avg: 15.,
    speech_end_count_rate: null,
  }))),
  {
    question_id: '735e7790-6a79-11eb-b3e2-5dafe951669d',
    view_count: 2,
    view_customer: 1,
    response_count: 1,
    response_count_rate: 0.5,
    response_customer: 1,
    response_customer_rate: 1.,
    friction_count: 4,
    friction_count_rate: 0.666,
    friction_customer: 1,
    friction_customer_rate: 1.,
    duration_avg: 10.,
    speech_end_count_rate: 1.,
  }, 
  {
    question_id: 'a904e730-6a79-11eb-b3e2-5dafe951669d',
    view_count: 1,
    view_customer: 1,
    response_count: 1,
    response_count_rate: 1.,
    response_customer: 1,
    response_customer_rate: 1.,
    friction_count: 0,
    friction_count_rate: 0.,
    friction_customer: 0,
    friction_customer_rate: 0.,
    duration_avg: 20.,
    speech_end_count_rate: 1.,
  },
  {
    question_id: 'b649f7a0-6a79-11eb-b3e2-5dafe951669d',
    view_count: 1,
    view_customer: 1,
    response_count: 1,
    response_count_rate: 1.,
    response_customer: 1,
    response_customer_rate: 1.,
    friction_count: 0,
    friction_count_rate: 0.,
    friction_customer: 0,
    friction_customer_rate: 0.,
    duration_avg: 0.,
    speech_end_count_rate: 1.,
  },
  {
    question_id: 'state_quiz_end',
    view_count: 2,
    view_customer: 1,
    response_count: 0,
    response_count_rate: 0.,
    response_customer: 0,
    response_customer_rate: 0.,
    friction_count: null,
    friction_count_rate: null,
    friction_customer: null,
    friction_customer_rate: null,
    duration_avg: null,
    speech_end_count_rate: null,
  }
];

export const MOCK_HIGH_FRICTION_QUESTION: HighFrictionQuestion[] = [{
  quiz_id: 676,
  question_id: '2',
  friction_count: 4,
  friction_customer: 1,
  view_count: 2,
  view_customer: 1,
  friction_customer_rate: 1,
  friction_count_rate: 4 / 6,
}];
