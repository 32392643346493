import { useEffect, useState } from 'react';
import { QuizIndexing } from '../interfaces/snapshot';
import { getQuizIndexing } from '../common/computationLambda';
import { useLocalStorageState } from './useLocalStorageState';

const QUIZ_INDEXING_LOCAL_STORAGE_KEY = 'GoblinQuizIndexing';

/**
 * Get quiz-indexing on page load, and cache in local storage.
 */
export function useQuizIndexing(): { quizIndexing: QuizIndexing; isLoadingQuizIndexing: boolean } {
  const [quizIndexing, setQuizIndexing] = useLocalStorageState<QuizIndexing>(QUIZ_INDEXING_LOCAL_STORAGE_KEY, {});
  const [isLoadingQuizIndexing, setIsLoadingQuizIndexing] = useState(true);
  useEffect(() => {
    getQuizIndexing().then(result => {
      if (result.quiz_indexing) {
        setIsLoadingQuizIndexing(false);
        setQuizIndexing(result.quiz_indexing);
      }
    });
  }, []);
  return { quizIndexing, isLoadingQuizIndexing };
}
