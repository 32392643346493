import React from 'react';
import {render} from 'react-dom';
import Amplify from 'aws-amplify';
import {reportWebVitals} from "./reportWebVitals";
import 'core-js/features/promise';
import {HashRouter} from 'react-router-dom';
import '@cloudscape-design/global-styles/index.css';
import App from "./App";
import {RegionDataMap} from "./interfaces";

export const regionalConfig: RegionDataMap[] = JSON.parse(process.env.RegionalConfig!) as RegionDataMap[];
console.log(regionalConfig)

const callbackUrl = window.location.origin;
const config = {
  oauth: {
    domain: `${process.env.UserPoolDomainPrefix}.auth.us-west-2.amazoncognito.com`, // dashboard stacks in us-west-2 only.
    scope: ['openid'],
    redirectSignIn: callbackUrl,
    redirectSignOut: callbackUrl,
    responseType: 'code'
  },
  // Cognito
  aws_cognito_identity_pool_id: process.env.IdentityPoolId,
  aws_cognito_region: "us-west-2",
  aws_user_pools_id: process.env.UserPoolId,
  aws_user_pools_web_client_id: process.env.UserPoolClientId,
  aws_project_region: 'us-west-2',
}

console.log('Amplify config', config)
Amplify.configure(config)


document.addEventListener('DOMContentLoaded', () => {
  render(
    <HashRouter>
      <App/>
    </HashRouter>,
    document.querySelector('#app')
  );
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
