import { Auth } from 'aws-amplify';
import { Lambda } from '@aws-sdk/client-lambda';
import { mockInvokeLambda, isMockEnabled } from './mockLambda';
import type { QuestionLevelMetrics, QuizLevelMetrics, QuizLevelMetricsWithQuizId } from '../interfaces/metrics';

export type QuizAggregation = 'quiz' | 'locale' | 'all';
export type TimeGranularity = 'day' | 'week' | 'month' | null;

const MetricsComputationLambdaName: { [stage: string]: string } = {
  'beta': 'Quizzical-Goblin-beta-Metrics-Computation',
  'prod': 'Quizzical-Goblin-prod-Metrics-Computation',
}
const lambdaName = process.env.metricsComputationLambdaName ?? MetricsComputationLambdaName[process.env.stage ?? 'beta'];

async function invokeLambda(payload: any): Promise<any> {
  console.log('invokeLambda => ', payload)

  if (isMockEnabled()) {
    return mockInvokeLambda(payload);
  }

  const lambda = new Lambda({ credentials: Auth.currentCredentials, region: 'us-west-2' });
  try {
    const result = await lambda.invoke({
      FunctionName: lambdaName,
      Payload: new TextEncoder().encode(JSON.stringify(payload))
    });

    console.log('invokeLambda <=', payload.task, payload.metrics_name)
    if (result.StatusCode !== 200) {
      console.error('Lambda invoke error', result.FunctionError, result.LogResult)
      return;
    }
    if (result.Payload === undefined) {
      console.error('Lambda response payload is undefined', result.FunctionError, result.LogResult)
      return;
    }

    try {
      return JSON.parse(new TextDecoder().decode(result.Payload));
    } catch (e: any) {
      console.error('Lambda response decode error', result.Payload);
    }
  } catch (e) { console.error(e); }
}

export async function getQuizIndexing() {
  const result = await invokeLambda({
    task: 'dashboard/quiz-indexing',
  })
  console.log(result)
  return result;
}


export async function getQuizJsons(quizIds?: number[]) {
  const result = await invokeLambda({
    task: 'dashboard/quiz-jsons',
    quiz_ids: quizIds,
  })
  console.log(result)
  return result;
}


export async function getQuizSummaryMetrics(
  dateStart: string, dateEnd: string, quizIds?: number[], viewportTypes?: string[]
): Promise<{ metrics: [QuizLevelMetrics] }> {
  const result = await invokeLambda({
    task: 'dashboard/metrics',
    metrics_name: 'quiz_level_metrics',
    date_start: dateStart,
    date_end: dateEnd,
    quiz_ids: quizIds,
    viewport_types: viewportTypes,
    time_granularity: null,
    quiz_aggregation: 'all',
  })
  console.log(result)
  return result;
}


export async function getHighFrictionQuestions(dateStart: string, dateEnd: string, quizIds?: string[], viewportTypes?: string[]) {
  const result = await invokeLambda({
    task: 'dashboard/metrics',
    metrics_name: 'high_friction_questions',
    date_start: dateStart,
    date_end: dateEnd,
    quiz_ids: quizIds,
    viewport_types: viewportTypes,
  })
  console.log(result)
  return result;
}


export async function getQuizLevelMetrics(
  dateStart: string,
  dateEnd: string,
  quizIds?: number[],
  viewportTypes?: string[],
): Promise<{ metrics: QuizLevelMetricsWithQuizId[] }> {
  const result = await invokeLambda({
    task: 'dashboard/metrics',
    metrics_name: 'quiz_level_metrics',
    date_start: dateStart,
    date_end: dateEnd,
    quiz_ids: quizIds,
    viewport_types: viewportTypes,
  })
  console.log(result)
  return result;
}


export async function getQuizLevelMetricsFile(
  filename: string,
  dateStart: string,
  dateEnd: string,
  quizIds?: number[],
  viewportTypes?: string[],
  quizAggregation?: QuizAggregation,
  timeGranularity?: TimeGranularity,
): Promise<{ url: string }> {
  const result = await invokeLambda({
    task: 'dashboard/metrics-file',
    metrics_name: 'quiz_level_metrics',
    date_start: dateStart,
    date_end: dateEnd,
    quiz_ids: quizIds,
    viewport_types: viewportTypes,
    quiz_aggregation: quizAggregation,
    time_granularity: timeGranularity,
    filename: filename,
  })
  return result;
}


export async function getQuestionLevelMetrics(
  dateStart: string,
  dateEnd: string,
  quizId: number,
  viewportTypes?: string[],
): Promise<{ metrics: QuestionLevelMetrics[] }> {
  const result = await invokeLambda({
    task: 'dashboard/metrics',
    metrics_name: 'question_level_metrics',
    date_start: dateStart,
    date_end: dateEnd,
    quiz_id: quizId,
    viewport_types: viewportTypes,
  })
  console.log(result)
  return result;
}


export async function getQuestionLevelMetricsFile(
  filename: string,
  dateStart: string,
  dateEnd: string,
  quizId?: number,
  viewportTypes?: string[],
  timeGranularity?: TimeGranularity,
): Promise<{ url: string }> {
  const result = await invokeLambda({
    task: 'dashboard/metrics-file',
    metrics_name: 'question_level_metrics',
    date_start: dateStart,
    date_end: dateEnd,
    quiz_id: quizId,
    viewport_types: viewportTypes,
    time_granularity: timeGranularity,
    filename: filename,
  })
  return result;
}
