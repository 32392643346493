import React from 'react';
import { useCollection } from "@cloudscape-design/collection-hooks";
import { Header, Link, Pagination, Table } from "@cloudscape-design/components";
import type { HighFrictionQuestion } from "../../../interfaces/metrics";
import type { QuizIndexing, QuizJsons } from "../../../interfaces/snapshot";
import LinkToEditorSite from "../../shared/linkToEditorSite";
import { RED_600 } from '../../../common/colors';
import SpeechPreview from '../../shared/speechPreview';
import { displayNumberAndPercentage, notUndefined } from '../../../common/utils';


export const FrictionTable: React.FC<{
  highFrictionQuestions: HighFrictionQuestion[],
  quizIndexing: QuizIndexing;
  quizJsons: QuizJsons;
  loading: boolean,
  infoTopicSelector: any,
}> = ({ highFrictionQuestions, quizIndexing, quizJsons, loading, infoTopicSelector }) => {

  const highFrictionQuestionsAndContent = highFrictionQuestions.map(metrics => {
    const quiz = quizIndexing[metrics.quiz_id];
    const question = quizJsons[metrics.quiz_id]?.nodes?.find(node => node.id === metrics.question_id);
    return {
      ...metrics,
      ...quiz,
      speech: question?.speech,
      authors: [...new Set([quiz.create_by, quiz.update_by])].filter(notUndefined).join(','),
    }
  });
  const { items, actions, filteredItemsCount, collectionProps, filterProps, paginationProps } = useCollection(
    highFrictionQuestionsAndContent,
    {
      pagination: { pageSize: 10 },
      sorting: {
        defaultState: {
          sortingColumn: { sortingField: 'friction_customer_rate' },
          isDescending: true
        }
      },
    }
  );
  return (
    <Table
      loading={loading}
      items={items}
      header={
        <Header variant="h3" info={
          <>
            <Link variant="info" onFollow={() => infoTopicSelector('frictionInfo')}>
              - What is a friction?
            </Link>
            &nbsp;
            <Link variant="info" href="https://wiki.labcollab.net/confluence/display/Doppler/Quizzical+User+Guide+-+Friction" target="_blank">
              - How to reduce friction?
            </Link>
          </>
        }>
          <span style={{ color: RED_600 }}>High-Friction Questions</span>
        </Header>}
      columnDefinitions={[
        {
          id: "link",
          header: null,
          width: 40,
          cell: row => row.locale && <LinkToEditorSite quizId={row.quiz_id} locale={row.locale} />,
        },
        {
          id: "quizName",
          header: "Quiz Name",
          cell: row => row.quiz_name,
        },
        {
          id: "quizId",
          header: "Quiz ID",
          width: 100,
          cell: row => row.quiz_id,
        },
        {
          id: "nodeId",
          header: "Node ID",
          width: 140,
          cell: row => row.question_id,
        },
        {
          id: "questionSpeech",
          header: "Question Speech",
          cell: row => <SpeechPreview speech={row.speech ?? ""} />,
          sortingField: 'speech',
        },
        {
          id: "customers",
          header: "Customers Impacted",
          cell: row => displayNumberAndPercentage(row.friction_customer, row.friction_customer_rate),
          sortingField: "friction_customer_rate",
        },
        {
          id: "instances",
          header: "Occurance",
          cell: row => displayNumberAndPercentage(row.friction_count, row.friction_count_rate),
          sortingField: "friction_count_rate",
        },
        {
          id: "authors",
          header: "Authors",
          cell: row => row.authors,
          sortingField: 'authors',
        },
      ]}
      resizableColumns
      pagination={<Pagination {...paginationProps} />}
      empty="No high-friction question."
    />
  );
}
