import { MILLIS_IN_DAY } from "./utils";

export const VIEWPORT_FEATURE_START_DATE_STR = '2023-10-12';
export const GOBLIN_DATA_START_DATE_STR = '2023-04-12';
export const GOBLIN_DATA_START_DATE = new Date(Date.UTC(2023, 4, 12));

export const dateOfLatestData = () => new Date(Date.now() - 2 * MILLIS_IN_DAY); // data of (today-1) is not ready yet

/** Format Date as yyyy-MM-dd */
export const formatDate = (date: Date) => {
  const year = date.getUTCFullYear();
  const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // Months are zero-indexed
  const day = String(date.getUTCDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
}
