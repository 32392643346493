import { regionalConfig } from '../index';
import { MILLIS_IN_HOUR, MILLIS_IN_DAY } from './utils';

export function getDataRegionOptions() {
  return regionalConfig.map(r => ({ label: r.region, value: r.region }));
}

export function getInitialDateRange() {
  // load data for one day before - data for current day is always changing.
  // typically, for last 6 hours, too many updates. so, we go to the day which ended 6 hours from current time.
  // const endDate = new Date(Date.now() - 30 * MILLIS_IN_HOUR);
  const endDate = new Date(Date.now() - 2 * MILLIS_IN_DAY);
  const startDate = new Date(endDate.getTime() - 6 * MILLIS_IN_DAY);
  return {
    startDate: startDate.toISOString().split('T')[0],
    endDate: endDate.toISOString().split('T')[0],
    type: 'absolute' as 'absolute'
  };
}
