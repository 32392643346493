import React, {useEffect} from "react";
import DateRangePicker, {DateRangePickerProps} from "@cloudscape-design/components/date-range-picker";
import AbsoluteValue = DateRangePickerProps.AbsoluteValue;
import { GOBLIN_DATA_START_DATE, GOBLIN_DATA_START_DATE_STR, dateOfLatestData, formatDate } from "../../../common/date";

// taken from https://tiny.amazon.com/1lrqr3x2

const differenceInDays = (dateOne: string | number | Date, dateTwo: string | number | Date) => {
  // @ts-ignore
  const milliseconds = Math.abs(new Date(dateTwo) - new Date(dateOne));
  return Math.ceil(milliseconds / (1000 * 60 * 60 * 24));
};

const isValidRangeFunction = (range: { type: string; startDate: string; endDate: string; amount: number; unit: any; }): any => {
  // If 'relative' option is clicked with/without value, return. (for now)
  if (!range || range?.type === 'relative') return

  if (range.type === 'absolute') {
    const [startDateWithoutTime] = range.startDate.split('T');
    const [endDateWithoutTime] = range.endDate.split('T');

    if (!startDateWithoutTime || !endDateWithoutTime) {
      return {
        valid: false,
        errorMessage: 'The selected date range is incomplete. Select a start and end date for the date range.'
      }
    }
    if (startDateWithoutTime < GOBLIN_DATA_START_DATE_STR) {
      return {
        valid: false,
        errorMessage: `Sorry, Goblin does not have data earlier than ${GOBLIN_DATA_START_DATE_STR}.`
      };
    }
    if (endDateWithoutTime > formatDate(dateOfLatestData())) {
      return {
        valid: false,
        errorMessage: `Sorry, data of ${endDateWithoutTime} is not ready yet.`
      };
    }
  }
  return {valid: true};
};

export function DateRangeSelector(props: {
  saveDateRange: (range: DateRangePickerProps.AbsoluteValue) => void,
  initialState: DateRangePickerProps.AbsoluteValue,
  lock: boolean
}) {
  const [value, setValue] = React.useState<DateRangePickerProps.AbsoluteValue>(props.initialState);

  useEffect(() => {
    console.log("rendering date range filter", JSON.stringify(value));
  });

  return (
    <DateRangePicker
      onChange={({detail}) => {
        setValue(detail.value as AbsoluteValue);
        props.saveDateRange(detail.value as AbsoluteValue)
      }}
      value={value}
      isValidRange={isValidRangeFunction as DateRangePickerProps.ValidationFunction}
      isDateEnabled={date => date >= GOBLIN_DATA_START_DATE && date <= dateOfLatestData()}
      i18nStrings={{
        todayAriaLabel: "Today",
        nextMonthAriaLabel: "Next month",
        previousMonthAriaLabel: "Previous month",
        customRelativeRangeDurationLabel: "Duration",
        customRelativeRangeDurationPlaceholder:
          "Enter duration",
        customRelativeRangeOptionLabel: "Custom range",
        customRelativeRangeOptionDescription:
          "Set a custom range in the past",
        customRelativeRangeUnitLabel: "Unit of time",
        formatRelativeRange: e => {
          const t =
            1 === e.amount ? e.unit : `${e.unit}s`;
          return `Last ${e.amount} ${t}`;
        },
        formatUnit: (e, t) => (1 === t ? e : `${e}s`),
        dateTimeConstraintText:
          "The metrics have up to 24 HOURS OF DELAY.",
        relativeModeTitle: "Relative range",
        absoluteModeTitle: "Absolute range",
        relativeRangeSelectionHeading: "Choose a range",
        startDateLabel: "Start date",
        endDateLabel: "End date",
        startTimeLabel: "Start time",
        endTimeLabel: "End time",
        clearButtonLabel: "Clear and dismiss",
        cancelButtonLabel: "Cancel",
        applyButtonLabel: "Apply"
      }}
      disabled={props.lock}
      dateOnly
      showClearButton={false}
      placeholder="Filter by a date and time range"
      rangeSelectorMode="absolute-only"
      relativeOptions={[]}/>
  );
}
