import React, { useState } from 'react';
import { Header, Multiselect, SpaceBetween, type MultiselectProps, ColumnLayout, TokenGroup, TokenGroupProps, SelectProps, TextContent, Input } from '@cloudscape-design/components';

import { FlashbarProps } from '@cloudscape-design/components/flashbar';
import { QuizIndexing } from '../../../interfaces/snapshot';

const LOCALES = [
  'ar-SA',
  'de-DE',
  'en-AU',
  'en-CA',
  'en-GB',
  'en-IN',
  'en-US',
  'es-ES',
  'es-MX',
  'es-US',
  'fr-CA',
  'fr-FR',
  'hi-IN',
  'it-IT',
  'ja-JP',
  'pt-BR',
];



export function QuizSelector(props: {
  // onChange: (filterStates: { [name: string]: any }) => void,
  quizIndexing: QuizIndexing,
  setNotificationsItems: (items: FlashbarProps.MessageDefinition[]) => void,
  infoTopicSelector: (topic: string) => void;
  selectedQuizIds: (number|string)[]; // quizId | locale | 'all'
  setSelectedQuizIds: (quizIds: number[]) => void;
  loadingQuizIndexing: boolean;
}) {

  const { quizIndexing, selectedQuizIds, setSelectedQuizIds } = props;

  // Emergency actions
  // const [input, setInput] = useState('');
  // return (
  //   <Input 
  //     placeholder='Input Quiz IDs, seperated with space'
  //     value={input}
  //     onChange={(e) => {
  //       setInput(e.detail.value);
  //       setSelectedQuizIds(e.detail.value.split(' '));
  //     }}
  //   />
  // )

  const [selectedQuizzes, setSelectedQuizzes] = useState<any[]>([]);

  const allQuizOptions = [
    
    ...(props.loadingQuizIndexing ? [] : LOCALES).map(locale => ({
      label: `(All quizzes in ${locale})`,
      value: locale,
      // description: '',
      key: locale,
      quizIds: getQuizIdOfLocale(locale, quizIndexing),
      // locale,
    })),
    ...[...Object.values(quizIndexing)].map(quiz => ({
      label: `${quiz.quiz_name}`,
      value: `${quiz.quiz_id}`,
      description: `ID=${quiz.quiz_id} | Locale=${quiz.locale}`,
      key: `${quiz.quiz_id}`,
      quizIds: [quiz.quiz_id],
      // locale: `${quiz.locale},`
    })),
  ]
  // console.log('quizOptions', allQuizOptions)


  const onQuizChange = ({ detail }: { detail: MultiselectProps.MultiselectChangeDetail }) => {
    const newSelectedQuizzes = detail.selectedOptions as any[];
    setSelectedQuizzes(newSelectedQuizzes);
    setSelectedQuizIds(
      [...new Set(newSelectedQuizzes.flatMap(q => q.quizIds))]
    )
  };
  // const onDismissToken = ({ detail: { itemIndex } }: { detail: TokenGroupProps.DismissDetail }) => {
  //   const newSelectedQuizzes = [
  //     ...selectedQuizzes.slice(0, itemIndex),
  //     ...selectedQuizzes.slice(itemIndex + 1)
  //   ]
  //   setSelectedQuizIds(newSelectedQuizzes);
  // }


  return (
    <>
      <Header variant={'h2'}>
        Quiz Selection
      </Header>
      <Multiselect
        statusType={props.loadingQuizIndexing ? 'loading' : 'finished'}
        filteringPlaceholder='Search by quiz name / locale / ID'
        selectedOptions={selectedQuizzes}
        onChange={onQuizChange}
        deselectAriaLabel={e => `Remove ${e.label}`}
        options={allQuizOptions}
        filteringType='auto'
        placeholder='select or search quizzes'
        selectedAriaLabel='Selected'
      />
      <TextContent>
        {
          selectedQuizIds.length ? `${selectedQuizIds.length} quizzes selected` : `All quizzes selected`
        }
      </TextContent>
    </>
  )
}

// function getQuizOptions(quizzes: { quiz_id: string, locale: string, quiz_name: string }[]): SelectProps.Options {
//   return quizzes.map(quiz => ({
//     label: `${quiz.quiz_name}`,
//     value: `${quiz.quiz_id}`,
//     description: `ID: ${quiz.quiz_id}`,
//     key: `${quiz.quiz_id}`,
//   }));
// }



function getQuizIdOfLocale(locale: string, quizIndexing: QuizIndexing) {
  return [...Object.values(quizIndexing)].filter(quiz => quiz.locale === locale).map(quiz => quiz.quiz_id);
}
