
import { MOCK_QUIZ_INDEXING, MOCK_QUIZ_JSONS } from '../utils/mocks/snapshot';
import { MOCK_HIGH_FRICTION_QUESTION, MOCK_QUESTION_LEVEL_METRICS, MOCK_QUIZ_LEVEL_METRICS, MOCK_SUMMARY_METRICS } from '../utils/mocks/metrics';

export function mockInvokeLambda(payload: any) {
  switch (payload.task) {
    case 'dashboard/quiz-indexing':
      return { quiz_indexing: MOCK_QUIZ_INDEXING };
    case 'dashboard/quiz-jsons':
      return { quiz_jsons: MOCK_QUIZ_JSONS };
    case 'dashboard/metrics':
      switch (payload.metrics_name) {
        case 'high_friction_questions':
          return { metrics: MOCK_HIGH_FRICTION_QUESTION };
        case 'quiz_level_metrics':
          if (payload.time_granularity === null && payload.quiz_aggregation === 'all') {
            return { metrics: MOCK_SUMMARY_METRICS };
          } else {
            return { metrics: MOCK_QUIZ_LEVEL_METRICS };
          }
        case 'question_level_metrics':
          return { metrics: MOCK_QUESTION_LEVEL_METRICS };
        default:
          throw new Error('Unexpected mock payload.metrics_name');
      }
    case 'dashboard/metrics-file':
      return new Promise(res => {
        setTimeout(() => res({ url: 'https://www.amazon.com/favicon.ico' }), 1000);
      });
    default:
      throw new Error('Unexpected mock payload.task');
  }
}

export function isMockEnabled() {
  return window.location.search.includes('mock');
}
