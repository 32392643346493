import { Header } from '@cloudscape-design/components';
import { DateRangeSelector } from './dateRangeSelector';

import type { DateRangePickerProps } from '@cloudscape-design/components/date-range-picker';
import type { FlashbarProps } from '@cloudscape-design/components/flashbar';

export function DataSelector(props: {
  setNotificationsItems: (items: FlashbarProps.MessageDefinition[]) => void,
  infoTopicSelector: (topic: string) => void;
  dateRange: DateRangePickerProps.AbsoluteValue,
  setDateRange: (range: DateRangePickerProps.AbsoluteValue) => void,
}) {
  return (
    <>
      <Header variant={'h2'}>Select Date Range</Header>
      <DateRangeSelector saveDateRange={props.setDateRange} initialState={props.dateRange} lock={false}/>
    </>
  );
}
