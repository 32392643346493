import React, { type ReactNode } from 'react';
import { Button, Icon, Popover, type PopoverProps } from '@cloudscape-design/components';

/**
 * Known issue: when there is layout change for icon element (such as page shrink on opening toolbox), 
 * the popover will not to move with the icon.
 * This is an issue with Popover component. No easy way to fix. Ignore for now as it's not harmful.
 */

const InfoButton = (
  <Button
    variant='inline-icon'
    iconSvg={<Icon name='status-info' variant='subtle' size='small' />}
  />
);

export const InfoPopover: React.FC<{
  content: ReactNode,
  top?: true,
  size?: PopoverProps.Size,
}> = ({ content, top, size = 'large' }) => (
  <span onClick={e => e.stopPropagation()}>
    <Popover
      content={content}
      position={top && 'top'}
      size={size}
      dismissButton={false}
      triggerType='custom'
      renderWithPortal
    >
      {InfoButton}
    </Popover>
  </span>
);
