import React, { useEffect } from 'react';
import { Button, ExpandableSection, Form, FormField, Modal, RadioGroup, RadioGroupProps, SpaceBetween, type DateRangePickerProps } from '@cloudscape-design/components';
import { getQuestionLevelMetricsFile, getQuizLevelMetricsFile, type QuizAggregation, type TimeGranularity } from '../../../common/computationLambda';
import { type QuizPlayFilterType } from '../quizPlayFilter';
import { DownloadStatus } from './downloadStatus';


type MetricsTypeOption =
  'QuizLevelMetrics' | // of individual quiz
  'QuizLevelMetrics-AggregateByLocale' |
  'QuizLevelMetrics-AggregateAll' |
  'QuestionLevelMetrics';
type TimeGranularityOption = Exclude<TimeGranularity, null> | 'none'; // Radio component only accepts string as option value; use 'none' to represent null

const timeGranularityOptions: RadioGroupProps.RadioButtonDefinition[] = [
  { value: 'day', label: 'Daily' },
  { value: 'week', label: 'Weekly' },
  { value: 'month', label: 'Monthly' },
  { value: 'none', label: 'All-period' },
];

function getDownloadFilename(dateRange: DateRangePickerProps.AbsoluteValue, quizIds: number[], metricsType: MetricsTypeOption, timeGranularity: TimeGranularityOption) {
  let quizSelectionStr = '';
  if (metricsType === 'QuestionLevelMetrics') {
    quizSelectionStr = `Questions-of-${quizIds[0]}`;
  } else { // metricsType === 'QuestionLevelMetrics-*'
    if (quizIds.length === 0) {
      quizSelectionStr = 'All-quizzes';
    } else if (quizIds.length === 1) {
      quizSelectionStr = `Quiz-id-${quizIds[0]}`;
    } else {
      quizSelectionStr = `${quizIds.length}-quizzes`;
    }
  }
  let quizAggregationStr = '';
  if (metricsType === 'QuizLevelMetrics-AggregateByLocale') {
    quizAggregationStr = '_Aggregate-by-locale';
  } else if (metricsType === 'QuizLevelMetrics-AggregateAll') {
    quizAggregationStr = '_Aggregate-all';
  }
  const dateStr = dateRange.startDate.replaceAll('-', '') + '-' + dateRange.endDate.replaceAll('-', ''); // remove '-' for compact
  const timeGranularityStr = timeGranularityOptions.find(x => x.value === timeGranularity)?.label;
  const idStr = crypto.randomUUID().slice(-6);
  return `Quizzical_${quizSelectionStr}${quizAggregationStr}_${dateStr}_${timeGranularityStr}_${idStr}.csv`;
}

export function MetricsDownload({ dateRange, selectedQuizIds, quizPlayFilter, downloadStatusRef, setDownloadStatus }: {
  dateRange: DateRangePickerProps.AbsoluteValue;
  selectedQuizIds: number[];
  quizPlayFilter: QuizPlayFilterType;
  downloadStatusRef: { readonly current: DownloadStatus };
  setDownloadStatus: (newValue: DownloadStatus) => void;
}) {
  const { viewportTypes } = quizPlayFilter;
  const [isModalVisible, setIsModalVisibleVisible] = React.useState(false);
  const [metricsType, setMetricsType] = React.useState<MetricsTypeOption>('QuizLevelMetrics');
  const [timeGranularity, setTimeGranularity] = React.useState<TimeGranularityOption>('none');

  const onClickExport = async () => {
    setIsModalVisibleVisible(false);
    const timeGranularityLambdaArg = timeGranularity === 'none' ? null : timeGranularity;
    const filename = getDownloadFilename(dateRange, selectedQuizIds, metricsType, timeGranularity);
    setDownloadStatus([...downloadStatusRef.current, { filename, loading: true }]);

    let result: any;
    if (metricsType === 'QuestionLevelMetrics') {
      result = await getQuestionLevelMetricsFile(filename, dateRange.startDate, dateRange.endDate, selectedQuizIds[0], viewportTypes, timeGranularityLambdaArg);
    } else {
      let quizAggregation: QuizAggregation;
      if (metricsType === 'QuizLevelMetrics') {
        quizAggregation = 'quiz';
      } else if (metricsType === 'QuizLevelMetrics-AggregateByLocale') {
        quizAggregation = 'locale';
      } else { // 'QuizLevelMetrics-AggregateAll'
        quizAggregation = 'all';
      }
      result = await getQuizLevelMetricsFile(filename, dateRange.startDate, dateRange.endDate, selectedQuizIds, viewportTypes, quizAggregation, timeGranularityLambdaArg);
    }

    // Find this download's status and update it
    setDownloadStatus(
      downloadStatusRef.current.map(item =>
        item.filename === filename ? { filename, loading: false, url: result?.url } : item
      )
    );
  };

  const isOneQuizSelected = selectedQuizIds.length === 1;

  // reset metricsType when it becomes disabled due to selectedQuizIds changes
  useEffect(() => {
    if (
      !isOneQuizSelected && metricsType === 'QuestionLevelMetrics' ||
      isOneQuizSelected && (metricsType === 'QuizLevelMetrics-AggregateByLocale' || metricsType === 'QuizLevelMetrics-AggregateAll')
    ) {
      setMetricsType('QuizLevelMetrics');
    }
  });

  return (
    <div>
      <Button onClick={() => setIsModalVisibleVisible(true)} iconName='download'>Export as CSV</Button>
      <Modal
        header='Export Settings'
        visible={isModalVisible}
        onDismiss={() => setIsModalVisibleVisible(false)}
      >
        <Form
          actions={<Button variant='primary' onClick={onClickExport}>Export</Button>}
        >
          <SpaceBetween direction='vertical' size='l'>
            <ul>
              <li>Date range: {dateRange.startDate} - {dateRange.endDate}</li>
              <li>Selected quizzes: {selectedQuizIds.length === 0 ? 'All quizzes' : `${selectedQuizIds.length} quizzes`}</li>
              {viewportTypes.length > 0 && <li>Device type: {viewportTypes.length} types</li>}
            </ul>
            <FormField label='Metrics type'>
              <RadioGroup
                onChange={({ detail }) => setMetricsType(detail.value as MetricsTypeOption)}
                value={metricsType}
                items={[
                  {
                    value: 'QuizLevelMetrics',
                    label: 'Quiz Level Metrics (by individual quiz, no aggregation)'
                  }, {
                    value: 'QuizLevelMetrics-AggregateByLocale',
                    label: 'Quiz Level Metrics (aggregate quizzes by locale)',
                    description: 'Available when more than one quiz is selected',
                    disabled: isOneQuizSelected
                  }, {
                    value: 'QuizLevelMetrics-AggregateAll',
                    label: 'Quiz Level Metrics (aggregate all quizzes)',
                    description: 'Available when more than one quiz is selected',
                    disabled: isOneQuizSelected
                  }, {
                    value: 'QuestionLevelMetrics',
                    label: 'Question Level Metrics',
                    description: 'Available when exactly one quiz is selected',
                    disabled: !isOneQuizSelected
                  },
                ]}
              />
            </FormField>
            <FormField label='Time granularity'>
              <RadioGroup
                onChange={({ detail }) => setTimeGranularity(detail.value as TimeGranularityOption)}
                value={timeGranularity}
                items={timeGranularityOptions}
              />
            </FormField>
            <p>We have recently updated the unit of quiz/question duration from <i>millisecond</i> to <i>second</i>.</p>
            <ExpandableSection headerText='Garbled characters in Excel? Correct way to import CSV:'>
              <p>The exported CSV files are UTF-8 encoded. You might need to specify this encoding when importing into Excel.</p>
              <ol>
                <li>Open or create an Excel workbook.</li>
                <li>In the system menu bar, click File {'>'} Import.</li>
                <li>In the pop-up window, select "CSV file", click "Import".</li>
                <li>Choose the exported CSV file.</li>
                <li>Selected "Delimited", "Start import at row 1", <strong>File origin "Unicode (UTF-8)"</strong>. Click "Next."</li>
                <li>Check only "Comma" for Delimiters, keep "Treat consecutive delimiters as one" unchecked, keep ["] selected as Text qualifier. Click "Finish."</li>
                <li>Choose either "Existing sheet" or "New Sheet" according to your need.</li>
              </ol>
            </ExpandableSection>
          </SpaceBetween>
        </Form>
      </Modal>
    </div>
  );
}
