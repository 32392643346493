import React, { useEffect } from 'react';
import { Button, Flashbar, FlashbarProps } from '@cloudscape-design/components';
import { DownloadStatus } from './downloadStatus';


const AutoDownloadButton: React.FC<{ url: string }> = ({ url }) => {
  useEffect(() => {
    window.open(url, '_blank');
  }, []);
  return <Button href={url} download>Download</Button>;
};

export const DownloadStatusBar: React.FC<{
  downloadStatus: DownloadStatus;
  setDownloadStatus: (value: DownloadStatus) => void;
}> = ({ downloadStatus, setDownloadStatus }) => {
  const flashbarItems: FlashbarProps.MessageDefinition[] = downloadStatus.map(({ filename, loading, url }) => {
    if (loading) {
      return {
        id: filename,
        type: 'info',
        loading: true,
        header: `Exporting table as file ${filename}...`,
        content: `Please do not close this tab...`,
      };
    } else {
      const onDismiss = () => setDownloadStatus(
        downloadStatus.filter(i => i.filename !== filename)
      );
      if (url) {
        return {
          id: filename,
          type: 'success',
          header: `Export complete: ${filename}`,
          content: `If download did not start automatically, click this button to download.`,
          action: <AutoDownloadButton url={url} />,
          dismissible: true,
          onDismiss,
        };
      } else {
        return {
          id: filename,
          type: 'warning',
          header: `Export failed for ${filename}`,
          content: <span>Please retry or cut us a <a href='https://i.amazon.com/issues/create?template=cc69f567-5d0f-41e7-af35-eaab877c0ffb'>SIM</a>.</span>,
          dismissible: true,
          onDismiss,
        };
      }
    }
  });
  flashbarItems.reverse(); // latest download at top
  return (
      <div style={{ position: 'sticky', bottom: 10, maxWidth: 800, marginLeft: 'auto', marginTop: 12, zIndex: 3000 }}>
        <Flashbar items={flashbarItems} />
      </div>
  );
}
